@import "./v1/head";
@import "./v2/head";
@import "./v3/head";
@import "./video/head";
@import "./parallax/head";

.breadcrumbs {
  @apply text-gray no-underline mb-sm;
  * {
    @extend .text-md;
  }
  span a {
    @extend .text-md;
    @apply text-gray no-underline;
    transition: 0.5s;
    &:hover {
      @apply text-black;
    }
  }
  .breadcrumb_last {
    @apply text-black;
    font-weight: bold;
  }
}