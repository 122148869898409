.slider-gallery-v1 {
  .slider-gallery {
    &__text-over {
      position: absolute;
      right: 3rem;
      top: 3rem;
      z-index: 999;
    }
  }
  .swiper-container {
    // overflow: visible;
  }
  .swiper-slide {
    // width: auto !important;
    // height: 45rem;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      max-height: 80vh;
      object-fit: cover;
    }

    @media screen and (max-width: 767px) {
      img {
        min-height: 80vw;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    left: inherit;
    right: inherit;
    top: 50%;
    width: 4rem;
    height: 3.5rem;
    @apply flex items-center justify-center;
    transition: .5s;
  }


  .swiper-button-prev {
    left: 3rem;
    &:hover {
      transform: translateX(-2rem);
    }
  }
  .swiper-button-next {
    right: 3rem;
    &:hover {
      transform: translateX(2rem);
    }
  }

  .swiper-pagination {
    position: absolute;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);
    pointer-events: all;
    display: flex;
    justify-content: center;
    z-index: 10;
    // background-color: rgba($color: #000000, $alpha: .5);
    border-radius: 3rem;
    padding: 0 .5rem;

    .swiper-pagination-bullet {
      margin: 0.5rem 0.3rem;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      border: 1px solid #fff;
      display: inline-block;
      transition: .5s;
      &-active, &:hover {
        @apply bg-white;
      }
    }
  }
}
