.slider-anchor {
  &__links {
    position: absolute;
    top: 0;
    left: 0;
    @apply mt-md z-10 flex flex-wrap flex-col-reverse;
    width: 12rem;
    @screen lg {
      width: 18rem;
    }
    a {
      @apply w-full relative flex justify-end items-end pt-md pb-sm;
      @screen lg {
        @apply pt-lg;
      }
      span {
        @extend .title-md;
        line-height: 5rem;
        @apply ml-sm;
        transition: .3s;
        @screen lg {
          @extend .title-lg;
        }
      }
      &:before {
        content: '';
        @apply w-full h-1 bg-black absolute left-0 bottom-0;
        transition: .5s;
      }
      &.active {
        &:before {
          @apply h-2;
        }
        span {
          font-weight: bold;
        }
      }
    }
  }
}
