
.head-parallax {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    position: relative;
    padding-top: 13rem;
    min-height: 100vh;
    @screen h-md {
      padding-top: 20rem;
    }
    @screen h-lg {
      padding-top: 22rem;
    }
    .head {
        &__arrow {
            @apply absolute left-0 bottom-0 w-full;
        }
    }
    .parallax-mouse {
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      > div {
        width: 100%;
        height: 100%;
        img {
          height: 100%;
          width: auto;
          max-width: inherit;
          margin: auto;
          // object-fit: contain;
          // max-width: inherit;
          // @screen md {
          //   width: 100%;
          //   height: auto;
          // }
        }
        &:nth-child(1) img{
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      } 
    }
}