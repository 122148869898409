.footer {
  &-v2 {
    .logo__background {
      left: 0;
      top: 5rem;
      background: white;
      height: 82px;
      width: calc(
        ((100vw - 1280px + 2rem) / 2) +
          ((100vw / 4) - (100vw - 1280px + 2rem) / 4)
      );

      // width: calc(((100vw - 1280px + 2rem) / 2) + ((100vw / 4)));

      position: absolute;
      transform: translateY(-25%);
      clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);

      @media screen and (max-width: 1280px) {
        // width: calc((100vw / 4) + 2rem);
        width: calc(280px + 2rem);
      }
    }

    p {
      @extend .text-sm;
      margin-bottom: 1rem;
    }

    .menu {
      @apply mt-md;
    }

    .title-sm {
      font-weight: bold;
    }

    .menu,
    .menu ul {
      padding-left: 0;
      li {
        list-style: none;
        @apply mb-sm;
        a {
          @extend .text-lg;
          font-weight: bold;
          @apply no-underline;
        }
      }
    }
    a {
      transition: all 0.5s;
      &:hover {
        opacity: 0.7;
      }
    }

    #menu-naviga-2 {
      @media screen and (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
}
