.card-team {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    &__img {
        position: relative;
        aspect-ratio: 1/1;
        @apply bg-gray-light flex items-center justify-center w-full;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:last-child {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: .5s;
            }
        }
    }
    &:hover {
        .card-team {
            &__img {
                img {
                    &:last-child {
                        opacity: 1;
                    }
                }
            }
        }
    }
    &--vuota {
        .card-team__text {
            align-items: center;

            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }
    }
    &--young {
        .card-team {
            &__img {
                aspect-ratio: 2/3;
                }
            }
    }
}