.button {
  &-v3 {
    font-weight: 300;
    letter-spacing: 4px;
    @apply uppercase relative;
    padding-left: 2rem;
    &:before {
      content: '';
      width: 0.9rem;
      height: 0.9rem;
      position: absolute;
      top: 0.4rem;
      left: 0;
      @apply bg-black rounded-full;
    }
    strong,
    b {
      position: relative;
      letter-spacing: 4px;
      transition: .3s;
      z-index: 1;
      &:before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        @apply bg-black;
        transition: .3s;
        z-index: -1;
      }
    }

    a {
      text-decoration: none;
    }
    &:hover {
      strong,
      b {
        @apply text-white;
        &:before {
          height: 100%;
        }
      }
    }
  }
}
