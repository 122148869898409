.cta {
  .title-lg {
    b,
    strong {
      @apply text-black;

      @media screen and (max-width: 767px) {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }

  .button-v3--md {
    padding: 1rem 2rem;

    @media screen and (max-width: 767px) {
      padding: 1rem 2rem;

      .title-sm {
        font-size: 18px;
      }
    }
  }
}
