
.gallery-grid {
    @apply flex flex-wrap;
    flex-direction: row;
    &__item {
        // height: 45rem;
        height: 30vw;
        width: 30%;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &:nth-child(4n-3),
        &:nth-child(4n) {
            width: 70%;
        }
        @media screen and (max-width: 767px) {
            width: 50% !important;
            aspect-ratio: 1/1;
            height: auto;
        }
    }
}