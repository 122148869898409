.pagination {
  &-v1 {
    display: flex;
    justify-content: center;
    align-items: center;
    span,
    a {
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      //   background-color: theme("colors.white");
      //   border: 2px solid theme("colors.brand_02.dark");
      //   color: theme("colors.brand_02.dark");
      @extend .title-sm;
      margin: 0.5rem;
      transition: 0.5s;
      text-decoration: none;

      @apply border-black border-2 text-black;

      path {
        transition: 0.5s;
      }

      &.current, &:hover {
        @apply border-black text-white bg-black;
      }

      // &:hover {
      //   // @apply border-gray border text-gray;

      //   &.page-numbers {
      //     @apply bg-black text-white border-black;
      //   }

      //   &.current {
      //     @apply bg-transparent text-black;
      //   }
      // }
      &.next,
      &.prev {
        @apply text-black;
        border: 0px;
        @apply bg-transparent;
        svg {
          path {
            fill: theme("colors.black.default");
          }
        }

        &:hover {
          @apply bg-transparent;
        }
      }
    }
  }
}
