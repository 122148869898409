.header-v1 {
  width: 100%;
  z-index: 99;
  height: 13rem;
  @screen h-md {
    // height: 20rem;
  }
  @screen h-lg {
    // height: 22rem;
  }
  .header-height {
    height: 13rem;
    @screen h-md {
      // height: 20rem;
    }
    @screen h-lg {
      // height: 22rem;
    }
  }

  .logo {
    position: relative;
    z-index: 9999999;
    a {
      display: block;
    }
    img {
      // width: 200px;
      // height: auto;
      // display: block;
    }
    &--black {
      opacity: 1;
      transition: .5s;
    }
    &--white {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: .5s;
    }
    &.logo-white {
      .logo--black {
        opacity: 0;
      }
      .logo--white {
        opacity: 1;
      }
    }
  }
  &.header--open {
    .logo--black {
      opacity: 0;
    }
    .logo--white {
      opacity: 1;
    }
    .menu-icon span {
      @apply bg-white;
    }
  }

  &.active-scroll {
    .logo-white {
      .logo--black {
        opacity: 1;
      }
      .logo--white {
        opacity: 0;
      }
    }
  }


  .header__controls {
    z-index: 1000;
  }

  .header__contact {
    &:hover {
      //   @apply bg-black-dark;
    }
  }

  .hamburger-inner {
    &:before,
    &:after,
    & {
      background-color: theme("colors.black.default") !important;
      height: 0.2rem;
      border-radius: 0px;
    }
  }
  


  // &.header--white {
  //   > div > hr {
  //     color: #fff;
  //   }
  //   .menu-icon {
  //     span {
  //       background-color:#fff;
  //     }
  //   }
  // }



}

.header-aside {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  overflow: auto;
  // transition: 0.5s;
  // pointer-events: none;
  height: 0;
  overflow: hidden;
  // @apply bg-black-light;
  background-color: #3D3D3D;

  .element-animate {
    transition: none;
  }

  &--open {
    opacity: 1;
    pointer-events: inherit;
  }

  .hr {
    @apply text-black;
  }

  ul {
    list-style: none;
    padding: 0;
    li a {
      @apply relative block;
      transition: .5s;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        @apply bg-black;
        position: absolute;
        left: 0;
        bottom: 0;
        // transition: .5s;
      }
      &.active, &:hover {
        @apply text-white;
        transform: translateX(1rem);
      }
    }
  }

  &__menu-lg {
    ul {
      li {
        a {
          @extend .title-lg;
          font-weight: bold;
          text-decoration: none;
          @apply text-black py-lg;
        }
        &:last-child a {
          &:after {
            display: none;
          }
          @screen lg {
            &:after {
              display: block;
            }
          }
        }
      }
    }
  }

  &__menu-md {
    ul {
      li {
        a {
          text-decoration: none;
          @apply text-black py-md;
          @extend .title-sm;
          font-weight: bold;
        }
      }
    }
  }

  &__menu-sm {
    ul {
      @screen lg {
        columns: 2;
      }
      li {
        a {
          text-decoration: none;
          @apply text-black py-xs;
          @extend .text-lg;
          font-weight: bold;
          &::after {
            display: none;
          }
        }
      }
    }
  }


}




.menu-icon {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;

  .menu-icon__cheeckbox {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    z-index: 2;
    -webkit-touch-callout: none;
    position: absolute;
    opacity: 0;
  }
  div {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    // width: 22px;
    // height: 12px;
    width: 3.5rem;
    height: 1.2rem;
  }
  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--bar-bg, #000);
    border-radius: 1px;
    transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

    &:first-of-type {
      top: 0;
    }
    &:last-of-type {
      bottom: 0;
    }
  }
  &.is-active,
  .menu-icon__cheeckbox:checked + div {
    span {
      &:first-of-type {
        transform: rotate(45deg);
        top: 5px;
      }
      &:last-of-type {
        transform: rotate(-45deg);
        bottom: 5px;
      }
    }
  }

  &.is-active:hover span:first-of-type,
  &.is-active:hover span:last-of-type,
  &:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
  &:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
    width: 3.5rem;
  }

  &:hover {
    // no need hover effect on mobile.
    @media (min-width: 1024px) {
      span:first-of-type {
        width: 26px;
      }

      span:last-of-type {
        width: 12px;
      }
    }
  }
}



.header-v1 {
  @media screen and (max-width: 767px) {
    &:before {
      content: '';
      width: 100%;
      height: 0rem;
      background-color: #fff;
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      transition: .5s;
    }
  }
  &.header--fixed {
    @media screen and (max-width: 767px) {
      &:before {
        height: 7rem;
      }
    }
    .logo {
      position: fixed;
      left: 3rem;
      top: -10rem;
      transition: .5s;
      img {
        // transition: 0s;
        object-fit: cover;
        object-position: 0 0;
        height: 6rem;
      }
      @media screen and (max-width: 767px) {
        left: 2rem;
        img {
          height: 5rem;
        }
      }
    }
    .header__controls {
      position: fixed;
      right: 3rem;
      top: -10rem;
      transition: .5s;
      @media screen and (max-width: 767px) {
        right: 2rem;
      }
    }
  }
  &.active-scroll {
    .logo {
      top: 2rem;
      img {
        object-fit: cover;
        object-position: 0 0;
        width: 6rem;
        height: 6rem;
      }
      @media screen and (max-width: 767px) {
        top: 0rem;
        img {
          width: 5rem;
          height: 5rem;
        }
      }
    }
    .header__controls {
      top: 3rem;
      @media screen and (max-width: 767px) {
        top: 1rem;
      }
    }
  }
}


.overflow-hidden  .header-v1:before {
  display: none;
}

@media screen and (min-width: 768px) {
  .header--white:not(.header--active) {
    .menu-icon span {
      @apply bg-white;
    }
    .logo--white {
      opacity: 1;
    }
  }
}