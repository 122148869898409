
.list-blog-v1 {
    svg {
        width: 20px;
        height: 53px;
        @screen md {
            width: auto;
            height: auto;
        }
    }
    .list-post__item a {
        > div {
            transition: .5s;
        }
        &:hover {
            > div:first-child {
                @apply text-gray;
            }
            > div:last-child {
                transform: translateX(1rem);
                @apply text-black;
            }
        }
    }
}