.single-post__content {
  padding-top: 13rem;
  @screen h-md {
    padding-top: 20rem;
  }
  @screen h-lg {
    padding-top: 22rem;
  }
  .img-post {
    object-fit: cover;
    @screen md {
      height: 60rem;

    }
  }
  .container-content {
    h2 {
        @extend .title-sm;
        font-weight: bold;
    }
     h3, h4, h5 {
        @extend .title-xs;
        font-weight: bold;
    }

  }


}
