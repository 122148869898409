.slider-iqd {

  &__numero {
    .numero-lg {
      // @extend .title-3xl;
      font-weight: bold;
      font-size: 20rem;
      line-height: 20rem;
      @screen lg {
        font-size: 30rem;
        line-height: 30rem;
      }
    }
  }


  .swiper-container {
    // overflow: visible;
  }
  .swiper-slide {
    width: auto !important;
    padding: 0 20rem;
    // width: 100%;
    opacity: .2;
    transition: .8s;
    img {
      width: auto;
      height: 35rem;
      @screen md {
        height: 55rem;
      }
    }

    &-active {
      opacity: 1;
    }

    // @media screen and (max-width: 767px) {
    //   img {
    //     height: auto;
    //     max-height: 20rem;
    //   }
    // }
    
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    left: inherit;
    right: inherit;
    top: inherit;
    width: 3.5rem;
    height: 3.5rem;
    transition: .5s;
    // @apply flex items-center justify-center;
    &.swiper-button-disabled {
      pointer-events: none;
      opacity: .2;
    }
    svg {
      width: 3.5rem;
      @screen md {
        width: auto;
      }
    }
  }
  .swiper-button-prev {
    top: 57%;
    left: 8px;
    @screen md {
      left: calc(50% - 30rem);
      &:hover {
        transform: translateX(-2rem);
      }
    }
  }
  .swiper-button-next {
    top: 57%;
    right: 8px;
    @screen md {
      right: calc(50% - 30rem);
      &:hover {
        transform: translateX(2rem);
      }
    }
  }

  // .swiper-button-next,
  // .swiper-container-rtl .swiper-button-prev {
  // }

  // .swiper-button-prev,
  // .swiper-container-rtl .swiper-button-next {
  // }


  .swiper-pagination {
    position: absolute;
    left: 50%;
    bottom: 8rem;
    transform: translateX(-50%);
    pointer-events: all;
    display: flex;
    justify-content: center;
    z-index: 10;
    // background-color: rgba($color: #000000, $alpha: .5);
    border-radius: 3rem;
    padding: 0 .5rem;

    .swiper-pagination-bullet {
      margin: 0.5rem 0.3rem;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      border: 1px solid #000;
      display: inline-block;
      transition: .5s;
      &-active, &:hover {
        @apply bg-black;
      }
    }
  }
}


.sliders-child {
  // padding: 20rem;
  .slider-child__wrapper {
    // padding: 20rem 0;
    // height: 100vh;
  }
  .iqd-close {
    position: absolute;
    right: 3rem;
    top: 3rem;
  }
  .swiper-container {
    overflow: inherit;
    .swiper-wrapper {
      align-items: center;
    }
    .swiper-slide {
      width: 100%;
      img {
        width: auto;
        max-width: 100%;
        margin: auto;
        display: block;
        max-height: 80vh;
        object-fit: contain;
      }
    }
  }
  

  .swiper-pagination {
    position: absolute;
    left: 50%;
    bottom: -8rem;
    transform: translateX(-50%);
    pointer-events: all;
    display: flex;
    justify-content: center;
    z-index: 10;
    // background-color: rgba($color: #000000, $alpha: .5);
    border-radius: 3rem;
    padding: 0 .5rem;

    .swiper-pagination-bullet {
      margin: 0.5rem 0.3rem;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      border: 1px solid #000;
      display: inline-block;
      transition: .5s;
      &-active, &:hover {
        @apply bg-black;
      }
    }
  }
  
}

