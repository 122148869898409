.head-v2 {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding-top: 13rem;
  // padding-top: 16rem;
  min-height: 100vh;
  // @screen h-md {
  //   padding-top: 20rem;
  //   padding-top: 22rem;
  // }
  // @screen h-lg {
  //   padding-top: 22rem;
  //   padding-top: 28rem;
  // }
  h1 {
    white-space: nowrap;
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    svg {
      display: inline-block;
      transform: translate(-30px, 10px);
      height: 10rem;
      @screen md {
        height: 20rem;
      }
    }
  }
}
