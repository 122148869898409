.card-post-v3 {
  a {
    .card-post__img {
      overflow: hidden;
      img {
        transition: 1s;
      }
    }
    &:hover {
      .card-post__img {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}
