.footer-v1 {
  a {
    text-decoration: none;
  }
  .logo-footer {
    width: 3.2rem;
  }
  &__top {
    .container {
      position: relative;
      p {
        position: relative;
        z-index: 9;
        line-height: 4.5rem;
        // @screen md {
        //   line-height: 6rem;
        // }
        a {
          transition: .5s;
          &:hover {
            opacity: .8;
          }
        }
      }
    }
    svg {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }
  }
}


.social-icons {
  img {
    width: 2.3rem;
  }
}
