.hero-v1 {
    &__text {
      max-width: 35rem;
    }
    >div {
      flex-direction: row;
      min-height: 40vw;
    }
    a {
      transition: .8s;
      display: inline-block;
      &:hover {
        transform: translateX(1rem);
        opacity: .8;
      }
    }
}

.hero-gallery {
  @apply absolute flex;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  &__col {
    width: 40%;
    @apply flex flex-wrap content-center;
    &:last-child {
      img {
        transform: translateY(10rem);
      }
    }
  }
  img {
    // width: 33rem;
    width: 100%;
    max-width: inherit;
    @apply px-md py-sm;
  }
}

.hero-gallery__single {
  width: 100%;
  height: 40vw;
  object-fit: cover;
}
