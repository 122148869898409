
.head-video {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    @apply relative;
    // min-height: calc(100vh - 15rem);
    // margin-top: 15rem;
    clear: both;
    box-sizing: border-box;

    margin-top: 13rem;
    min-height: calc(100vh - 13rem);
    @screen md {
      margin-top: 27rem;
    min-height: calc(100vh - 27rem);
    }

    // @screen h-md {
    //   min-height: calc(100vh - 23rem);
    //   margin-top: 23rem;
    // }
    // @screen h-lg {
    //   min-height: calc(100vh - 27rem);
    //   margin-top: 27rem;
    // }
    &__bg {
        @apply absolute left-0 top-0 w-full h-full overflow-hidden;
        video {
          @apply w-full h-full;
          object-fit: cover;
        }
    }
    .head {
        &__arrow {
            @apply absolute left-0 bottom-0 w-full;
            svg path {
              fill: #fff;
            }
        }
    }
}