.button-v2 {
  @apply text-black relative py-md;
  text-decoration: none;
  display: inline-block;
  span {
    transition: .8s;
    display: inline-block;
  }
  &:before {
    content: '';
    width: 100%;
    height: 1px;
    display: block;
    @apply bg-black;
    transition: .5s;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    display: block;
    @apply bg-black;
    transition: .5s;
    position: absolute;
    top: 0;
    left: 0;
  }
  a {
    text-decoration: none;
  }
  &:hover {
    span {
      transform: translateX(1rem);
      opacity: .8;
    }
  }
}
