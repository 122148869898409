// .button-v1 {
//     @extend .text-md;
//     @apply text-black uppercase border border-black py-sm px-md inline-block;
// }

// $btn-width: 250px !default;
$btn-width: 100% !default;
$btn-height: 80px !default;
$btn-height: auto !default;
// $btn-full: $btn-height+$btn-width !default;
// $bg-color: #eeeeee !default;


// a {
//   text-decoration: none;
//   line-height: $btn-height;
//   color: black;
// }

.centerer {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1rem
}



.button-v1 {
    @apply text-black uppercase border border-black py-sm px-md inline-block;
    position: relative;
    display: block;
    overflow: hidden;
    max-width: $btn-width;
    width: 100%;
    margin: 1rem auto;
    text-transform: uppercase;
    border: 1px solid currentColor;
    z-index: 1;
    transition: .5s;
    text-decoration: none;
    @extend .text-md;
    
    &:before {
        content: '';
        @include absolute(0,0,0,0);
        z-index: -1;
        transition: .5s;
        height: 100%;
        transform: translateY(100%);
        @apply bg-black;
    }

    // &:before {
    //     right: -50px;
    //     border-right: 50px solid transparent;
    //     border-bottom: $btn-height solid #000;
    //     transform: translateX(-100%);
    // }
    
    // &:after {
    //     left: -50px;
    //     border-left: 50px solid transparent;
    //     border-top: $btn-height solid #000;
    //     transform: translateX(100%);
    // }

    &:hover {
        // color: tint($btn-color, 75%);
        @apply text-white;

        // &:before { transform: translateX(-49%); }
        // &:after { transform: translateX(49%); }

        &:before {
            transform: translateY(0);
        }
    
    }


    &--white {
        @apply text-white;
        &:before {
            // border-bottom: $btn-height solid #fff;
            @apply bg-white;
        }
        
        // &:after {
        //     border-top: $btn-height solid #fff;
        // }
    
        &:hover {
            @apply text-black;
        }
    
      
    }
}

