.share-buttons {
    a {
        @apply bg-black flex justify-center items-center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        padding: 0.6rem;
        svg {
            width: auto;
            height: 100%;
            path {
                fill: #fff;
            }
        }
    }
}