
.content-post__head {

  padding-top: 13rem;
  @screen h-md {
    padding-top: 20rem;
  }
  @screen h-lg {
    padding-top: 22rem;
  }
}


.post-categories {
    &__link {
        @apply text-gray;
        transition: .5s;
        &--active, &:hover {
            @apply text-black;
        }
    }
}