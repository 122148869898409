.list-case-studies-v2 {
    .case-studies-item {
        &__link {
            max-width: 11rem;
            display: flex;
            align-items: flex-end;
            &::before {
                content: '';
                width: 3rem;
                height: 1px;
                display: block;
                @apply bg-white mr-sm;
                margin-bottom: 3px;
            }
        }
    }
}