

//gallery
@import "./gallery/v1/slider";
// @import "./gallery/v2/slider";

@import "./team";
@import "./anchor";
@import "./text";
@import "./portfolio";
@import "./iqd";


.swiper-buttons {
  position: absolute;
  right: 2%;
  bottom: 2%;
  display: flex;
  align-items: center;
  z-index: 10;
  .swiper-button-next,
  .swiper-button-prev {
    left: initial;
    right: initial;
    position: initial;
    width: 100%;
    height: 100%;
  }

  .swiper-button-prev {
    margin-right: -2px;
  }
}
