
.head-v1 {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    @apply relative;
    padding-top: 13rem;
    min-height: 100vh;
    @screen h-md {
      padding-top: 20rem;
    }
    @screen h-lg {
      padding-top: 22rem;
    }
    .slash svg {
      height: 10rem;
      width: auto;
      @screen md {
        height: 20rem;
      }
    }
    .head {
        &__arrow {
            @apply absolute left-0 bottom-0 w-full;
        }
    }
    .container {
      @apply py-xl;
      // max-height: 100%;
    }
}