@import "./mixin.scss";

@import "./input-button/index.scss";
@import "./input-number/index.scss";

/// Inputs
input:focus,
* {
  outline: none;
}

input,
input[type="text"],
input[type="email"],
input[type="phone"],
textarea,
select {
  @apply py-sm text-white bg-transparent;
  @extend .text-md;
  box-shadow: none;
  width: 100%;
  display: block;
  box-sizing: border-box;
  border-bottom: 1px solid #fff;
  
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  //   background: #fff url("../../dist/images/down-arrow.svg");
  background-repeat: no-repeat;
  background-position: 98% 50%;
  background-size: 15px;
}
// select {
//   @extend .text-s;
//   color: -internal-light-dark-color(black, #000);
//   background-color: #fff;
//   border-radius: 0px;
//   border-width: 1px;
//   border-style: solid;
//   border-color: rgb(166, 166, 166);
//   border-image: initial;
// }

.checkbox-inline,
.checkbox {
  display: block;
  width: 100%;
  margin: 10px 0;
  label,
  label p,
  label a {
    // @extend .text-sm;
    @apply text-white;
    display: inline-block;
    margin-left: 0px !important;
  }
  p {
    margin-bottom: 0 !important;
  }
}

input[type="checkbox"] {
  @apply border-white border-2;
  width: 18px !important;
  height: 18px !important;
  appearance: none;
  box-shadow: none;
  margin: 0 5px 0 0; //  cursor: pointer;
  display: inline-block;
  border-radius: 0px;
  transition: 0.5s;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    @apply border-white;
  }
  &:before {
    width: 6px;
    height: 12px;
    display: block;
    position: absolute;
    content: "";
    border-right: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(45deg) translate(-15px, -15px);
    margin: 0px 4px;
    opacity: 0;
    transition: 0.5s;
    @apply text-white;
  }
  &:checked {
    @apply border-white;
    &:before {
      transform: rotate(45deg) translate(0px, 0px);
      opacity: 1;
    }
  }
}
input[type="radio"] {
  width: 18px !important;
  height: 18px !important;
  appearance: none;
  box-shadow: none;
  border: none;
  margin: 0 5px 0 0;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transition: 0.5s;
  background-color: theme("colors.ui.2");
  border: 1px solid theme("colors.ui.3");
  cursor: pointer;
  vertical-align: middle;
  &:hover {
    border-color: theme("colors.ui.6");
  }
  &:before {
    width: 8px;
    height: 8px;
    display: block;
    position: absolute;
    content: "";
    background-color: theme("colors.ui.6");
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.5s;
  }
  &:checked {
    border-color: theme("colors.ui.6");
    &:before {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

input,
select,
textarea {
  margin-bottom: 2rem;
  border-radius: 0 !important;
  @extend .text-md;

  &::placeholder {
    color: #fff !important;
  }
}

.wpcf7 {
  .wpcf7-list-item {
    margin: 0;
  }
}


.wpcf7-form {
  .wpcf7-spinner {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
  }

  .container {
    padding: 0 !important;
  }

  .field.file {
    margin-bottom: 2rem;

    span {
      position: relative;
      max-height: 44px;
      cursor: pointer;
      overflow: hidden;

      &::before {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        content: "Carica CV";
        @apply bg-gray-light;
        pointer-events: none;
        width: 100%;
        height: 100%;
        padding: 1rem;
      }

      &::after {
        cursor: pointer;
        content: "";
        background-image: url("./imgs/document.svg");
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: 30px;
        height: 30px;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }

    input {
      margin-bottom: 0 !important;
    }
  }

  .field.select {
    .wpcf7-form-control-wrap {
      position: relative;

      &::after {
        content: "";
        background-image: url("./imgs/arrow.svg");
        display: block;
        position: absolute;
        top: 35%;
        right: 0;
        width: 30px;
        height: 30px;
        transform: translateY(-50%);
      }

      // &:hover {
      //   &::after {
      //     transform: translateY(-50%) rotate(-180deg);
      //   }
      // }
    }
  }

  .wpcf7-acceptance {
    cursor: pointer;
  }

  .cf7sg-response-output {
    margin: 1rem 0 !important;
  }

  input[type="checkbox"],
  input[type="submit"] {
    margin-bottom: 0;
  }

  input[type="submit"] {
    // width: auto !important;
    // border: none ;
    // border-radius: 0 !important;
    // left: 0 !important;
    // margin-left: 0 !important;
    cursor: pointer;
    margin-top: 2rem;
    @apply text-white border-white;
  }

  label {
    em {
      display: none !important;
    }
  }

  span.wpcf7-not-valid-tip {
    left: 0 !important;
    right: auto !important;
    bottom: -3px !important;
    padding: 3px 0 !important;
    background: transparent !important;
    font-size: 12px !important;
  }
}
