.youtube-video-place {
    position: relative;
    width: 100%;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    aspect-ratio: 16/9;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .block__video {
    position: relative;
    z-index: 1;
  }
  .play-youtube-video {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    .btn-play {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9;
      transform: translate(-50%, -50%);
      transition: 0.5s;
      &:before {
        content: '';
        @apply bg-black;
        width: 10rem;
        height: 10rem;
        position: absolute;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: .3;
        transition: 0.5s;
      }
      &:after {
        content: '';
        @apply bg-black;
        width: 8rem;
        height: 8rem;
        position: absolute;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: .5;
        transition: 0.5s;
      }
      svg {
        width: 4rem;
        height: auto;
        fill: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        transition: 0.5s;
      }
    }
    .lazy-bg {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
    &:hover {
      .btn-play {
        &:before {
          transform: translate(-50%, -50%) scale(1.15);
          opacity: .5;
        }
        &:after {
          transform: translate(-50%, -50%) scale(1.1);
          opacity: 1;
        }
      }
      svg {
        // transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }
  