
// .list-services-v1 {
//     img {
//         width: calc(100% + 10rem);
//         max-width: inherit;
//     }
//     a {
//         text-decoration: none;
//     }
// }



.cursor {
    // display: none;
    // @screen md {
    //     display: block;
    // }
    .circle {
        background: white;
        opacity: 0;
        // transform: scale(8);
    }
    .imageWrapper {
        width: 35rem;
        height: auto;
        transform: scale(0);
    }
    
    .circle-word {
        position: absolute;
        z-index:9999999999;
        @extend .text-sm;
        font-weight: bold;
    }
}