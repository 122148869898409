.list-services-v2 {
    .service-item {
        .title-md {
            transition: .8s;
            display: block;
        }
        &__link {
            max-width: 11rem;
            display: flex;
            align-items: flex-end;
            &::before {
                content: '';
                width: 3rem;
                height: 1px;
                display: block;
                @apply bg-black mr-sm;
                margin-bottom: 3px;
                transition: .5s;
            }
        }
        &:hover {
            .title-md {
                transform: translateX(-1rem);
            }
            .service-item__link {
                &::before {
                    width: 1rem;
                }
            }

        }
    }
}