.hero-v2 {
  .hero-orizontal {
    // height: 100vh;


    overflow-x: hidden;
    display: flex;
    // position: sticky;
    top:0;
    @apply px-lg;
    &__col {
      // min-width: 50vw;
      min-height: 100vh;
      display: flex;
      // justify-content: center;
      img {
        height: 50vh;
        width: auto;
        max-width: inherit;
        @apply my-3xl mx-lg;
      }
    }
  }
}


