
.list-services-v1 {
    img {
        width: 100%;
        max-width: inherit;
        @screen md {
            width: calc(100% + 10rem);
        }
    }
    a {
        text-decoration: none;
    }
    .title-xl {
        // span {
        //     transition: margin .5s ease-in-out;
        // }
        // &:hover {
        //     span {
        //         margin: 0 3px;
        //     }
        // }
    }
}

.animate-bold-letter {
    position: relative;
    b {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        // transition: .1s ease-in-out;
    }
}