
.head-v3 {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    @apply relative;
    padding-top: 13rem;
    @screen lg {
        min-height: 100vh;
        @screen h-md {
          padding-top: 20rem;
        }
        @screen h-lg {
          padding-top: 22rem;
        }
    }
    .head {
        &__arrow {
            @apply absolute left-0 bottom-0 w-full;
        }
    }
    .title-xxl {
        // span:nth-child(1) {
        //     position: relative;
        //     &:before {
        //         content: '';
        //         width: 100%;
        //         height: 1px;
        //         display: block;
        //         position: absolute;
        //         bottom: 1.3rem;
        //         left: 0;
        //         @apply bg-black;
        //     }
        // }
    }
    .split-title {
        min-height: 22rem;
    }
    &__logo {
        max-height: 15rem;
        max-width: 18rem;
        width: auto;
    }
}




.link-contents {
    @extend .title-xs;
    @apply uppercase flex flex-wrap items-end my-sm;
    @media screen and (max-width: 767px) {
        border-bottom: 1px solid #000;
        @apply p-sm w-full text-center block;
        font-size: 1.6rem;
    }
    span {
        @extend .text-sm;
        @apply hidden items-end mb-xs;
        &:before {
            content: '';
            width: 2rem;
            height: 1px;
            @apply bg-black mx-xs;
            transition: .5s;
        }
        @screen sm {
            @apply flex;
        }
    }
    &:hover {
        span {
            &:before {
                width: 1rem;
            }
        }
    }
}


#text {
    padding: 0 10px 0 0;
    margin: 0;
    border: 1px solid red;
  }
  
  #cursor {
    margin: 0;
    display: inline-block;
  }